import { HeadCell } from 'components/Table/types'
import { OrganizationBalance } from 'utils/global-types'
import { formatPrice } from 'utils/price'

export const balancesListColumns: HeadCell<OrganizationBalance>[] = [
  {
    label: 'Organization',
    value: 'name',
    width: 250,
    getHref: (row) => `/organizations/${row.uuid}`,
  },
  {
    label: 'Location',
    value: 'country',
    width: 130,
  },
  {
    label: 'Balance (USD)',
    value: (row) =>
      formatPrice(row.balances.usd, 'usd', {
        priceFirst: true,
      }),
    width: 130,
    align: 'right',
  },
  {
    label: 'Balance (CAD)',
    value: (row) =>
      formatPrice(row.balances.cad, 'cad', {
        priceFirst: true,
      }),
    width: 130,
    align: 'right',
  },
  {
    label: 'Balance (EUR)',
    value: (row) =>
      formatPrice(row.balances.eur, 'eur', {
        priceFirst: true,
      }),
    width: 130,
    align: 'right',
  },
]
