import { ElementType } from 'react'
import WalletIcon from '@mui/icons-material/AccountBalanceWalletOutlined'
import StatisticIcon from '@mui/icons-material/AssessmentOutlined'
import BoatIcon from '@mui/icons-material/DirectionsBoatFilledOutlined'
import InventoryIcon from '@mui/icons-material/Inventory2Outlined'
import PeopleIcon from '@mui/icons-material/PeopleAltOutlined'
import ReceiptIcon from '@mui/icons-material/ReceiptOutlined'
import ShoppingCartIcon from '@mui/icons-material/ShoppingCartOutlined'
import SyncAltIcon from '@mui/icons-material/SyncAltOutlined'
import UploadFileIcon from '@mui/icons-material/UploadFile'

import { Ability } from 'context/Ability'

export interface NavigationLink {
  id: string
  url: string
  label: string
  subject?: Ability[1]
  roots?: string[]
}

export const listItems: NavigationLink[] = [
  {
    id: 'customers',
    url: '/customers',
    label: 'Customers',
    subject: 'customer',
    roots: ['/customers', '/organizations'],
  },
  {
    id: 'quotes',
    url: '/quotes',
    label: 'Quotes',
    subject: 'customer',
  },
  {
    id: 'orders',
    url: '/orders',
    label: 'Orders',
    subject: 'customer',
  },
  {
    id: 'invoices',
    url: '/invoices',
    label: 'Invoices',
    subject: 'invoice',
  },
  {
    id: 'shipments',
    url: '/shipments',
    label: 'Shipments',
    subject: 'shipment',
  },
  {
    id: 'transactions',
    url: '/transactions',
    label: 'Transactions',
    subject: 'payment',
  },
  {
    id: 'products',
    url: '/products',
    label: 'Products',
    subject: 'product',
  },
  {
    id: 'reports',
    url: '/reports',
    label: 'Reports',
    subject: 'report',
  },
  {
    id: 'balances',
    url: '/balances',
    label: 'Balances',
    subject: 'customer',
  },
]

export const listIcons: Record<string, ElementType> = {
  customers: PeopleIcon,
  quotes: UploadFileIcon,
  orders: ShoppingCartIcon,
  invoices: ReceiptIcon,
  shipments: BoatIcon,
  transactions: SyncAltIcon,
  products: InventoryIcon,
  reports: StatisticIcon,
  balances: WalletIcon,
}
