import {
  createBrowserRouter,
  createRoutesFromElements,
  Navigate,
  Route,
} from 'react-router-dom'
import { Typography } from '@mui/material'

import AppProvider from 'app/AppProvider'
import { Link } from 'components/StyledLink'
import { AccountPage } from 'pages/Account'
import { BalancesListPage } from 'pages/BalancesList'
import { CustomerCreatePage } from 'pages/CustomerCreate'
import { CustomerDetailsPage } from 'pages/CustomerDetails'
import { CustomersListPage } from 'pages/CustomersList'
import { Dashboard } from 'pages/Dashboard'
import { NotFound } from 'pages/Error'
import { InvoiceCreateEditPage } from 'pages/InvoiceCreateEdit'
import { InvoicesList } from 'pages/InvoicesList'
import { Login } from 'pages/Login'
import { OrderEditPage } from 'pages/OrderEdit'
import { OrdersListPage } from 'pages/OrdersList'
import { OrganizationDetailsPage } from 'pages/OrganizationDetails'
import { OrganizationEditPage } from 'pages/OrganizationEdit'
import { PaymentsListPage } from 'pages/PaymentsList'
import { ProductDetailsPage } from 'pages/ProductDetails'
import { ProductListPage } from 'pages/ProductList'
import { QuoteCreatePage } from 'pages/QuoteCreate'
import { QuoteDetailsPage } from 'pages/QuoteDetails'
import { QuoteEditPage } from 'pages/QuoteEdit'
import { QuoteListPage } from 'pages/QuoteList'
import { Reports } from 'pages/Reports'
import { ShipmentsListPage } from 'pages/ShipmentsList'
import { Match } from 'routing/common'
import { ProtectedRoute } from 'routing/ProtectedRoute'
import { RouteCan } from 'routing/RouteCan'
import RouteErrorBoundary from 'routing/RouteErrorBoundary'

import { loaders } from './loaders'

export const routerConfig = createRoutesFromElements(
  <Route element={<AppProvider />} ErrorBoundary={RouteErrorBoundary}>
    <Route path="/" element={<ProtectedRoute />} loader={loaders.userDetails}>
      <Route
        ErrorBoundary={RouteErrorBoundary}
        handle={{
          crumb: () => <Link to="/">Home</Link>,
        }}
      >
        <Route index element={<Dashboard />} loader={loaders.dashboard} />
        <Route
          path="customers"
          element={<RouteCan I="view" a="customer" />}
          handle={{
            crumb: () => <Link to="/customers">Customers</Link>,
          }}
        >
          <Route index element={<CustomersListPage defaultTab="customers" />} />
          <Route
            path="new"
            handle={{
              crumb: () => <Link to="/customers/new">Add new customer</Link>,
            }}
            element={<CustomerCreatePage />}
          />
          <Route
            path=":customerId"
            element={<CustomerDetailsPage />}
            loader={loaders.customerDetails}
            handle={{
              crumb: (match: Match) => (
                <Link to={`/customers/${match.params.customerId}`}>Edit</Link>
              ),
            }}
          />
        </Route>
        <Route
          path="organizations"
          element={<RouteCan I="view" a="customer" />}
          handle={{
            crumb: () => <Link to="/organizations">Organizations</Link>,
          }}
        >
          <Route
            index
            element={<CustomersListPage defaultTab="organizations" />}
          />
          <Route
            path=":id"
            loader={loaders.organizationDetails}
            handle={{
              crumb: (match: Match) => (
                <Link to={`/organizations/${match.params.id}`}>Details</Link>
              ),
            }}
          >
            <Route index element={<Navigate to="customers" replace />} />
            <Route path="customers">
              <Route index element={<OrganizationDetailsPage />} />
              <Route
                path="add"
                element={<CustomerDetailsPage />}
                handle={{
                  crumb: () => (
                    <Typography color="text.primary">Add customer</Typography>
                  ),
                }}
              />
              <Route
                path=":customerId/edit"
                element={<CustomerDetailsPage />}
                loader={loaders.customerDetails}
                handle={{
                  crumb: () => (
                    <Typography color="text.primary">Edit customer</Typography>
                  ),
                }}
              />
            </Route>
            <Route path="orders" element={<OrganizationDetailsPage />} />
            <Route path="quotes" element={<OrganizationDetailsPage />} />
            <Route path="invoices" element={<OrganizationDetailsPage />} />
            <Route path="transactions" element={<OrganizationDetailsPage />} />
            <Route path="shipments" element={<OrganizationDetailsPage />} />
            <Route path="addresses" element={<OrganizationDetailsPage />} />
            <Route path="statements" element={<OrganizationDetailsPage />} />
            <Route
              path="edit"
              element={<OrganizationEditPage />}
              handle={{
                crumb: (match: Match) => (
                  <Link to={`/organizations/${match.params.id}/edit`}>
                    Edit
                  </Link>
                ),
              }}
            />
          </Route>
        </Route>
        <Route
          path="quotes"
          element={<RouteCan I="view" a="order" />}
          handle={{
            crumb: () => <Link to="/quotes">Quotes</Link>,
          }}
        >
          <Route index element={<QuoteListPage />} />
          <Route path="draft" element={<QuoteListPage />} />
          <Route path="review" element={<QuoteListPage />} />
          <Route path="discount" element={<QuoteListPage />} />
          <Route path="issued" element={<QuoteListPage />} />
          <Route path="ordered" element={<QuoteListPage />} />
          <Route path="rejected" element={<QuoteListPage />} />
          <Route path="expired" element={<QuoteListPage />} />
          <Route
            path="new"
            element={<QuoteCreatePage />}
            handle={{
              crumb: () => <Link to="/quotes/new">Create new quote</Link>,
            }}
          />
          <Route
            path=":id"
            loader={loaders.quoteDetails}
            handle={{
              crumb: (match: Match) => (
                <Link to={`/quotes/${match.params.id}`}>
                  Quote {match.params.id}
                </Link>
              ),
            }}
          >
            <Route index element={<QuoteDetailsPage />} />
            <Route
              path="edit"
              handle={{
                crumb: (match: Match) => (
                  <Link to={`/quotes/${match.params.id}/edit`}>Edit</Link>
                ),
              }}
            >
              <Route index element={<Navigate to="customer" replace />} />
              <Route path="customer" element={<QuoteEditPage />} />
              <Route path="products" element={<QuoteEditPage />} />
            </Route>
          </Route>
        </Route>
        <Route
          path="orders"
          element={<RouteCan I="view" a="order" />}
          handle={{
            crumb: () => <Link to="/orders">Orders</Link>,
          }}
        >
          <Route index element={<Navigate to="all" replace />} />
          <Route path="all" element={<OrdersListPage />} />
          <Route path="open" element={<OrdersListPage />} />
          <Route path="reopened" element={<OrdersListPage />} />
          <Route path="closed" element={<OrdersListPage />} />
          <Route path="canceled" element={<OrdersListPage />} />
          <Route path="unconfirmed" element={<OrdersListPage />} />
          <Route path="expired" element={<OrdersListPage />} />
          <Route
            path=":id"
            loader={loaders.orderDetails}
            handle={{
              crumb: (match: Match) => (
                <Link to={`/orders/${match.params.id}`}>
                  Order {match.params.id}
                </Link>
              ),
            }}
          >
            <Route index element={<OrderEditPage />} />
            <Route path="invoice" element={<RouteCan I="edit" a="invoice" />}>
              <Route index element={<Navigate to="new" replace />} />
              <Route
                path="new"
                element={<InvoiceCreateEditPage />}
                handle={{
                  crumb: (match: Match) => (
                    <Link to={`/orders/${match.params.id}/invoice/new`}>
                      Create invoice
                    </Link>
                  ),
                }}
              />
              <Route
                path=":invoiceId"
                element={<InvoiceCreateEditPage />}
                loader={loaders.invoiceDetails}
                handle={{
                  crumb: (match: Match) => (
                    <Link
                      to={`/orders/${match.params.id}/invoice/${match.params.invoiceId}`}
                    >
                      Invoice
                    </Link>
                  ),
                }}
              />
            </Route>
          </Route>
        </Route>
        <Route
          path="invoices"
          element={<RouteCan I="view" a="invoice" />}
          handle={{
            crumb: () => <Link to="/invoices">Invoices</Link>,
          }}
        >
          <Route index element={<Navigate replace to="all" />} />
          <Route path="all" element={<InvoicesList />} />
          <Route path="paid" element={<InvoicesList />} />
          <Route path="unpaid" element={<InvoicesList />} />
        </Route>
        <Route
          path="products"
          element={<RouteCan I="view" a="product" />}
          handle={{
            crumb: () => <Link to="/products">Products</Link>,
          }}
        >
          <Route index element={<ProductListPage />} />
          <Route path="custom" element={<ProductListPage />} />
          <Route path="inactive" element={<ProductListPage />} />
          <Route
            path="new"
            element={<ProductDetailsPage />}
            handle={{
              crumb: () => <Link to="new">Add new product</Link>,
            }}
          />
          <Route
            path=":sku"
            element={<ProductDetailsPage />}
            loader={loaders.productDetails}
            handle={{
              crumb: (match: Match) => (
                <Link to={`/products/${match.params.sku!}`}>Edit Product</Link>
              ),
            }}
          />
        </Route>
        <Route
          path="reports"
          element={<RouteCan I="view" a="report" />}
          handle={{
            crumb: () => <Link to="/reports">Reports</Link>,
          }}
        >
          <Route index element={<Reports />} loader={loaders.reports} />
        </Route>
        <Route
          path="shipments"
          element={<RouteCan I="view" a="shipment" />}
          handle={{
            crumb: () => <Link to="/shipments">Shipments</Link>,
          }}
        >
          <Route index element={<ShipmentsListPage />} />
        </Route>
        <Route
          path="transactions"
          element={<RouteCan I="view" a="payment" />}
          handle={{
            crumb: () => <Link to="/transactions">Transactions</Link>,
          }}
        >
          <Route index element={<PaymentsListPage />} />
        </Route>
        <Route
          path="balances"
          element={<RouteCan I="view" a="customer" />}
          handle={{
            crumb: () => <Link to="/balances">Balances</Link>,
          }}
        >
          <Route index element={<BalancesListPage />} />
        </Route>
        <Route
          path="account"
          handle={{
            crumb: () => <Link to="/account">Account page</Link>,
          }}
        >
          <Route index element={<Navigate to="profile" replace />} />
          <Route path="profile" element={<AccountPage />} />
          <Route path="configs" element={<RouteCan I="edit" a="config" />}>
            <Route
              index
              element={<AccountPage />}
              loader={loaders.configsTab}
            />
          </Route>
          <Route path="sync" element={<RouteCan I="force_sync" a="product" />}>
            <Route index element={<AccountPage />} loader={loaders.syncTab} />
          </Route>
        </Route>
        <Route path="*" element={<NotFound />} />
      </Route>
    </Route>
    <Route path="/login" element={<Login />} />
  </Route>
)

const router = createBrowserRouter(routerConfig)

export default router
