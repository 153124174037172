import { Typography } from '@mui/material'

import { Breadcrumbs } from 'components/Breadcrumbs'
import { Table, useTable } from 'components/Table'
import { PageHeader } from 'pages/Layout/PageHeader'
import { PageTemplate } from 'pages/Layout/PageTemplate'

import { balancesListColumns } from './utils'

export function BalancesListPage() {
  const table = useTable(
    'balances',
    '/customers/organizations/balances-list/',
    {
      columns: balancesListColumns,
    }
  )

  return (
    <PageTemplate title="Balances">
      <PageHeader>
        <Typography variant="h6">Balances</Typography>
        <Breadcrumbs />
      </PageHeader>
      <Table
        {...table}
        disableAddButton
        dataType="balances"
        searchPlaceholder="Organization name"
      />
    </PageTemplate>
  )
}
