import { PreviewTableCell } from 'components/PreviewTableCell'
import { HeadCell, TableFilters } from 'components/Table/types'
import DueDateCell from 'components/TableDueDateCell'
import { PaymentStatusCell } from 'components/TableStatusCell'
import { LinkTabI } from 'components/Tabs'
import { Invoice } from 'utils/global-types'
import { convertInvoiceType, convertShipmentStatus } from 'utils/helpers'
import { formatPrice } from 'utils/price'

import InvoiceShipmentButtons from './InvoiceShipmentButtons'

export type InvoicesListTab = 'all' | 'paid' | 'unpaid'

export const invoicesTabs: LinkTabI[] = [
  {
    label: 'All invoices',
    path: '/invoices/all',
  },
  {
    label: 'Paid',
    path: '/invoices/paid',
  },
  {
    label: 'Unpaid',
    path: '/invoices/unpaid',
  },
]

export const mapInvoicesListTabToParams: Record<InvoicesListTab, TableFilters> =
  {
    all: { ordering: '-created' },
    paid: { payment_status__in: 'PAID,OVERPAID', ordering: '-created' },
    unpaid: {
      payment_status__in: 'UNPAID,PARTIALLY_PAID',
      ordering: '-created',
    },
  }

type InvoiceListColumnsProps = {
  tab: InvoicesListTab
  canViewOrder: boolean
  canViewOrganization: boolean
}

export const getInvoicesListColumns = ({
  tab,
  canViewOrder,
  canViewOrganization,
}: InvoiceListColumnsProps): HeadCell<Invoice>[] => {
  return [
    {
      label: 'Created Date',
      value: 'created',
      width: 115,
    },
    {
      label: 'Order ID',
      value: 'order_id',
      width: 115,
      getHref: canViewOrder
        ? (invoice) => `/orders/${invoice.order_id}`
        : undefined,
    },
    {
      label: 'Supplier',
      value: 'supplier_order_id',
      width: 100,
    },
    {
      label: 'Invoice ID',
      width: 130,
      value: (invoice) => <PreviewTableCell {...invoice} />,
    },
    {
      label: 'PO #',
      width: 120,
      value: 'po_number',
    },
    {
      label: 'Organization',
      value: 'organization_name',
      width: 130,
      getHref: canViewOrganization
        ? (invoice) => `/organizations/${invoice.organization}`
        : undefined,
    },
    {
      label: 'Email',
      value: 'customer_email',
      width: 144,
    },
    {
      label: 'Invoice amount',
      width: 144,
      align: 'right',
      value: (invoice) =>
        formatPrice(invoice.total_amount, invoice.currency, {
          priceFirst: true,
        }),
    },
    ...(tab !== 'unpaid'
      ? [
          {
            label: 'Type',
            value: ({ type }: Invoice) => convertInvoiceType(type),
          },
        ]
      : []),
    {
      label: 'Status',
      value: ({ payment_status }) => (
        <PaymentStatusCell paymentStatus={payment_status} />
      ),
    },
    {
      label: 'Due Date',
      value: ({ due_date }) => <DueDateCell date={due_date ?? ''} />,
    },
    {
      label: 'Shipped',
      value: ({ shipment_status, is_credited, type }) =>
        shipment_status && !is_credited && type === 'DEBIT'
          ? convertShipmentStatus(shipment_status)
          : '',
    },
    {
      label: '',
      width: 95,
      value: (invoice) => <InvoiceShipmentButtons invoice={invoice} />,
    },
  ]
}
