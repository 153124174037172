import { Typography } from '@mui/material'
import { DateTime } from 'luxon'

import { localeDate } from 'utils/date'

type DueDateCellProps = {
  date: string
}

export default function DueDateCell({ date }: DueDateCellProps) {
  if (!date) {
    return null
  }

  const dateFormatted = localeDate(date)
  const daysDifference = DateTime.fromISO(date).diffNow().as('days')
  const isDateInPast = Math.ceil(daysDifference) < 0

  return (
    <Typography
      component="span"
      fontSize="inherit"
      fontWeight={isDateInPast ? 600 : 400}
      color={isDateInPast ? 'rgba(174, 0, 0, 0.87)' : 'text.primary'}
    >
      {dateFormatted}
    </Typography>
  )
}
